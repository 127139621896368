import produce from 'immer';

import {
  ROUTE_PROCESS_ERROR,
  ROUTE_PROCESS_REQUEST,
  ROUTE_PROCESS_SUCCESS,
} from './actions';

const INIT_STATE = {
  wayPoints: [],
  route: {},
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ROUTE_PROCESS_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;

      case ROUTE_PROCESS_SUCCESS:
        draft.loading = false;
        draft.wayPoints = action.payload.data.wayPoints;
        draft.route = action.payload.data.route;
        draft.error = '';
        break;

      case ROUTE_PROCESS_ERROR:
        draft.loading = false;
        draft.wayPoints = [];
        draft.route = {};
        draft.error = action.payload.message;
        break;

      default:
        return state;
    }
  });
};
