export const ROUTE_PROCESS_REQUEST = 'ROUTE_PROCESS_REQUEST';
export const ROUTE_PROCESS_SUCCESS = 'ROUTE_PROCESS_SUCCESS';
export const ROUTE_PROCESS_ERROR = 'ROUTE_PROCESS_ERROR';

export const processRoute = data => ({
  type: ROUTE_PROCESS_REQUEST,
  payload: { data },
});

export const onProcessRouteSuccess = data => ({
  type: ROUTE_PROCESS_SUCCESS,
  payload: { data },
});

export const onProcessRouteError = message => ({
  type: ROUTE_PROCESS_ERROR,
  payload: { message },
});
