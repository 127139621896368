import produce from 'immer';

import {
  AGGREGATE_BALANCE_SHARING_LIST_REQUEST,
  AGGREGATE_BALANCE_SHARING_LIST_SUCCESS,
  AGGREGATE_BALANCE_SHARING_LIST_ERROR,
} from './actions';

const INIT_STATE = {
  list: [],
  loading: false,
  error: '',
  success: false,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case AGGREGATE_BALANCE_SHARING_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case AGGREGATE_BALANCE_SHARING_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.error = '';
        break;
      }
      case AGGREGATE_BALANCE_SHARING_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }
      default:
        return state;
    }
  });
};
