import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  AGGREGATE_BALANCE_SHARING_LIST_REQUEST,
  listAggregateBalanceSharingSuccess,
  listAggregateBalanceSharingError,
} from './actions';

function* listAggregateBalanceSharing(api, { payload }) {
  const balanceSharing = yield call(
    api.list,
    APIParams(payload.params),
    payload.body
  );

  if (balanceSharing.ok) {
    const list = balanceSharing.data;

    yield put(listAggregateBalanceSharingSuccess({ list }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listAggregateBalanceSharingError(message));
  }
}

export function* watchListAggregateBalanceSharing(api) {
  yield takeEvery(
    AGGREGATE_BALANCE_SHARING_LIST_REQUEST,
    listAggregateBalanceSharing,
    api
  );
}

export default function* rootSaga(api) {
  yield all([fork(watchListAggregateBalanceSharing, api)]);
}
