import produce from 'immer';
import * as types from '../types';

const INIT_STATE = {
  data: null,
  loading: false,
  error: null,
  updating: false,
  updatingError: null,
  unsharing: false,
  unsharingError: null,
  sharing: false,
  sharingError: null,
  collectingCredit: false,
  collectingCreditError: null,
  params: {}
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case types.VEHICLE_DISTRIBUTION.REQUEST:
        draft.loading = true;
        draft.error = null;
        draft.params = action.payload.params;
        break;

      case types.VEHICLE_DISTRIBUTION.SUCCESS:
        draft.loading = false;
        draft.data = action.payload.data;
        draft.error = null;
        break;

      case types.VEHICLE_DISTRIBUTION.ERROR:
        draft.loading = false;
        draft.data = null;
        draft.error = action.payload.error;
        break;

      case types.VEHICLE_UNSHARE.REQUEST:
        draft.unsharing = true;
        draft.unsharingError = null;
        break;

      case types.VEHICLE_UNSHARE.SUCCESS:
        draft.unsharing = false;
        draft.unsharingError = null;
        break;

      case types.VEHICLE_UNSHARE.ERROR:
        draft.unsharing = false;
        draft.unsharingError = action.payload.error;
        break;

      case types.VEHICLE_TOGGLE_SHARE:
        draft.sharing = true;
        draft.sharingError = null;
        break;

      case types.VEHICLE_UPDATE.REQUEST:
        draft.updating = true;
        draft.updatingError = null;
        break;

      case types.VEHICLE_UPDATE.SUCCESS:
        draft.updating = false;
        draft.updatingError = null;
        break;

      case types.VEHICLE_UPDATE.ERROR:
        draft.updating = false;
        draft.updatingError = action.payload.error;
        break;

      case types.VEHICLE_COLLECT_CREDIT.REQUEST:
        draft.collectingCredit = true;
        draft.collectingCreditError = null;
        break;

      case types.VEHICLE_COLLECT_CREDIT.SUCCESS:
        draft.collectingCredit = false;
        draft.collectingCreditError = null;
        break;

      case types.VEHICLE_COLLECT_CREDIT.ERROR:
        draft.collectingCredit = false;
        draft.collectingCreditError = action.payload.error;
        break;

      default:
        return state;
    }
  });
};
