export const AGGREGATE_BALANCE_SHARING_LIST_REQUEST =
  'AGGREGATE_AGGREGATE_BALANCE_SHARING_LIST_REQUEST';
export const AGGREGATE_BALANCE_SHARING_LIST_SUCCESS =
  'AGGREGATE_BALANCE_SHARING_LIST_SUCCESS';
export const AGGREGATE_BALANCE_SHARING_LIST_ERROR =
  'AGGREGATE_BALANCE_SHARING_LIST_ERROR';

export const listAggregateBalanceSharing = (params, body) => ({
  type: AGGREGATE_BALANCE_SHARING_LIST_REQUEST,
  payload: { params, body },
});

export const listAggregateBalanceSharingSuccess = payload => ({
  type: AGGREGATE_BALANCE_SHARING_LIST_SUCCESS,
  payload,
});

export const listAggregateBalanceSharingError = message => ({
  type: AGGREGATE_BALANCE_SHARING_LIST_ERROR,
  payload: { message },
});
