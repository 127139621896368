import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import auth from './auth/reducer';
import user from './user/reducer';
import vehicle from './vehicle/reducer';
import container from './container/reducer';
import driver from './driver/reducer';
import recipient from './recipient/reducer';
import transaction from './transaction/reducer';
import organizations from './organizations/reducer';
import administrators from './administrators/reducer';
import reports from './reports/reducer';
import bills from './bills/reducer';
import deposits from './deposits/reducer';
import stations from './stations/reducer';
import maps from './maps/reducer';
import balance from './balance/reducer';
import hydrate from './hydrate/reducer';
import orgSettings from './orgSettings/reducer';
import balanceSharing from './balanceSharing/reducer';
import permissions from './permissions/reducer';
import userPermissions from './userPermissions/reducer';
import vehicleBalance from './balance/vehicleBalance/reducer';
import fuelManagement from './fuelManagement/reducer';
import purchase from './purchase/reducer';
import expenseCenter from './expenseCenter/reducer';
import expenseCenterUserBalance from './expenseCenterBalance/user/reducer';
import expenseCenterVehicleBalance from './expenseCenterBalance/vehicle/reducer';
import subscription from './subscription/reducer';
import fuelManagementWallets from './fuelManagement/wallets/reducer';
import fuelManagementUsers from './fuelManagement/users/reducer';
import routeScript from './routeScript/reducer';
import organizationInvoices from './organizationInvoices/reducer';
import aggregateTransactions from './aggregateTransactions/reducer';
import aggregateBalanceSharing from './aggregateBalanceSharing/reducer';

const reducers = combineReducers({
  menu,
  settings,
  auth,
  user,
  vehicle,
  container,
  driver,
  recipient,
  transaction,
  organizations,
  administrators,
  reports,
  bills,
  deposits,
  stations,
  maps,
  balance,
  hydrate,
  orgSettings,
  balanceSharing,
  permissions,
  userPermissions,
  vehicleBalance,
  fuelManagement,
  purchase,
  expenseCenter,
  expenseCenterUserBalance,
  expenseCenterVehicleBalance,
  subscription,
  fuelManagementWallets,
  fuelManagementUsers,
  routeScript,
  organizationInvoices,
  aggregateTransactions,
  aggregateBalanceSharing,
});

export default reducers;
