import * as types from '../types';

export const vehicleBalanceRequest = params => ({
  type: types.VEHICLE_DISTRIBUTION.REQUEST,
  payload: { params },
});

export const vehicleBalanceSuccess = data => ({
  type: types.VEHICLE_DISTRIBUTION.SUCCESS,
  payload: { data},
});

export const vehicleBalanceError = error => ({
  type: types.VEHICLE_DISTRIBUTION.ERROR,
  payload: { error },
});

export const updateVehicleBalance = (data, params) => ({
  type: types.VEHICLE_UPDATE.REQUEST,
  payload: { data , params},
});

export const updateVehicleBalanceSuccess = () => ({
  type: types.VEHICLE_UPDATE.SUCCESS,
});

export const updateVehicleBalanceError = error => ({
  type: types.VEHICLE_UPDATE.ERROR,
  payload: { error },
});

export const shareVehicleBalance = (id, status, params) => ({
  type: types.VEHICLE_TOGGLE_SHARE,
  payload: { id, status, params },
});

export const collectVehicleCredit = (id, params)=> ({
  type: types.VEHICLE_COLLECT_CREDIT.REQUEST,
  payload: { id, params },
});

export const collectVehicleCreditSuccess = () => ({
  type: types.VEHICLE_COLLECT_CREDIT.SUCCESS,
});

export const collectVehicleCreditError = error => ({
  type: types.VEHICLE_COLLECT_CREDIT.ERROR,
  payload: { error },
});

export const allowAnywhereVehicleRefueling = (vehicleId, data, params) => ({
  type: types.TOGGLE_ANYWHERE_VEHICLE_REFUELING,
  payload: { vehicleId, data, params},
});
