import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { serviceAPIParams } from 'helpers/Utils';
import { INTERNAL_SERVER_ERROR } from 'constants/defaultValues';
import { getCurrentOrganization } from '../organizations/selectors';

import {
  VEHICLE_LIST_REQUEST,
  VEHICLE_REGISTER_REQUEST,
  VEHICLE_DELETE_REQUEST,
  VEHICLE_EDIT_REQUEST,
  listVehicleRequest,
  listVehicleSuccess,
  listVehicleError,
  registerVehicleSuccess,
  registerVehicleError,
  editVehicleError,
  deleteVehicleError,
  undeleteVehicleError,
  VEHICLE_UNDELETE_REQUEST,
  VEHICLE_REGISTER_IMPORTED_REQUEST,
  registerVehicleImportedSuccess,
  registerVehicleImportedError,
} from './actions';

function* listVehicle(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const vehicles = yield call(
    api.listVehicle,
    serviceAPIParams({
      ...payload.params,
      organizationId: currentOrganization.id,
    })
  );

  if (vehicles.ok) {
    const { vehicles: list, metadata } = vehicles.data;

    const { current: currentPage, total: totalPages } = metadata.pages;
    const { total: totalItems, skip: skipItems = 0 } = metadata.items;

    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listVehicleSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listVehicleError(message));
  }
}

function* registerVehicle(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const apiRequest = yield call(
    api.registerVehicle,
    payload.body,
    currentOrganization.id
  );

  if (apiRequest.ok) {
    yield put(registerVehicleSuccess());
  } else {
    if (apiRequest.data.statusCode === INTERNAL_SERVER_ERROR) {
      apiRequest.data.message = 'Unable to create vehicle.';
    }
    yield put(registerVehicleError(apiRequest.data));
  }
  yield put(listVehicleRequest(payload.params));
}

function* registerVehicles(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const apiRequest = yield call(
    api.registerVehicles,
    payload.body,
    currentOrganization.id
  );

  if (apiRequest.ok) {
    yield put(registerVehicleImportedSuccess());
  } else {
    yield put(registerVehicleImportedError(apiRequest.data));
  }
  yield put(listVehicleRequest(payload.params));
}

function* deleteVehicle(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.deleteVehicle,
    payload.id,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(listVehicleRequest(payload.params));
  } else {
    const message = 'Não foi possível remover o veículo';
    yield put(deleteVehicleError(message));
  }
}

function* editVehicle(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(api.editVehicle, payload, currentOrganization.id);

  if (response.ok) {
    yield put(listVehicleRequest(payload.params));
  } else {
    if (response.data.statusCode === INTERNAL_SERVER_ERROR) {
      response.data.message = 'Unable to update vehicle.';
    }
    yield put(editVehicleError(response.data));
  }
}

function* undeleteVehicle(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.undeleteVehicle,
    payload.id,
    currentOrganization.id
  );

  if (response.ok) {
    yield put(listVehicleRequest(payload.params));
  } else {
    const message = 'Não foi possível recuperar o veículo';
    yield put(undeleteVehicleError(message));
  }
}

export function* watchListVehicleRequest(api) {
  yield takeEvery(VEHICLE_LIST_REQUEST, listVehicle, api);
}

export function* watchRegisterVehicleRequest(api) {
  yield takeEvery(VEHICLE_REGISTER_REQUEST, registerVehicle, api);
}

export function* watchUDeleteVehicleRequest(api) {
  yield takeEvery(VEHICLE_DELETE_REQUEST, deleteVehicle, api);
}

export function* watchEditVehicleRequest(api) {
  yield takeEvery(VEHICLE_EDIT_REQUEST, editVehicle, api);
}

export function* watchUndeleteVehicleRequest(api) {
  yield takeEvery(VEHICLE_UNDELETE_REQUEST, undeleteVehicle, api);
}

function* watchRegisterImportedVehicleRequest(api) {
  yield takeEvery(VEHICLE_REGISTER_IMPORTED_REQUEST, registerVehicles, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListVehicleRequest, api),
    fork(watchRegisterVehicleRequest, api),
    fork(watchEditVehicleRequest, api),
    fork(watchUDeleteVehicleRequest, api),
    fork(watchUndeleteVehicleRequest, api),
    fork(watchRegisterImportedVehicleRequest, api),
  ]);
}
