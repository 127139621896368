import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import { getCurrentOrganization } from '../organizations/selectors';
import * as selectors from './selectors';
import * as actions from './actions';
import * as types from './types';

function* handleDistributionRequest(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);
    const response = yield call(
      api.balanceDistribution,
      APIParams({ ...payload.params, organizationId: currentOrganization.id })
    );

    if (!response) {
      throw new Error('request error');
    }

    const data = {
      balance: response.data.balance,
      users: response.data.users.map(user => ({
        name: `${user.firstName} ${user.lastName}`,
        sharedBalance: user.wallets.some(wallet => wallet.sharedBalance)
          ? 1
          : 0,
        balance: user.wallets.reduce(
          (total, wallet) => total + wallet.balance,
          0
        ),
        id: user._user,
        role: user.role,
        userOrg: user._id,
        wallet: user.wallets[0] && user.wallets[0]._id,
        allowAnywhereRefueling:
          user.wallets[0] && user.wallets[0].allowAnywhereRefueling ? 1 : 0,
      })),
      hasAnywhere: response.data.hasAnywhere,
    };

    yield put(actions.balanceSuccess(data));
  } catch (error) {
    yield put(actions.balanceError(error.message));
  }
}

function* handleShareBalanceToggle(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);

    if (payload.status) {
      const response = yield call(api.unshareBalance, {
        organizationId: currentOrganization.id,
        userId: payload.userId,
      });

      if (!response) {
        throw new Error('request error');
      }
    }

    const { users, balance, hasAnywhere } = yield select(selectors.getBalance);
    let newBalanceValue = balance;

    const updatedUsers = users.map(user => {
      if (user.id === payload.userId) {
        if (user.balance) {
          newBalanceValue += user.balance;
          return {
            ...user,
            sharedBalance: +payload.status,
            balance: 0,
          };
        }

        return {
          ...user,
          sharedBalance: +payload.status,
        };
      }

      return user;
    });

    const data = {
      balance: newBalanceValue,
      users: updatedUsers,
      hasAnywhere,
    };

    yield put(actions.balanceSuccess(data));
  } catch (error) {
    yield put(actions.balanceError(error.message));
  }
}

function* handleUpdateBalanceRequest(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);
    const { users, balance, hasAnywhere } = yield select(selectors.getBalance);

    const totalToAdd = payload.data.reduce(
      (accumulator, currentValue) => accumulator.share + currentValue.share,
      { share: 0 }
    );

    if (totalToAdd > balance) {
      throw new Error('O saldo total ultrapassa o limite disponível');
    }

    const request = yield call(api.updateSharedBalance, {
      data: payload.data,
      organizationId: currentOrganization.id,
    });

    if (!request.ok) {
      throw new Error('update shared balance');
    }

    yield put(
      actions.balanceSuccess({
        balance: balance - totalToAdd,
        users,
        hasAnywhere,
      })
    );

    yield put(actions.updateBalanceSuccess());
    yield put(actions.balanceRequest(payload.params));
  } catch (error) {
    yield put(actions.updateBalanceError(error.message));
  }
}

function* handleCollectCreditRequest(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);
    const { users, balance, hasAnywhere } = yield select(selectors.getBalance);

    let collectedBalance;

    const dataWithUpdates = users.map(d => {
      if (d.id !== payload.userId) {
        return d;
      }

      collectedBalance = d.balance;
      return {
        ...d,
        balance: 0,
      };
    });

    const request = yield call(api.reapBalance, {
      userId: payload.userId,
      organizationId: currentOrganization.id,
    });

    if (!request.ok) {
      throw new Error('update shared balance');
    }

    yield put(
      actions.balanceSuccess({
        balance: balance + collectedBalance,
        users: dataWithUpdates,
        hasAnywhere,
      })
    );

    yield put(actions.collectCreditSuccess());

    yield put(actions.balanceRequest(payload.params));
  } catch (error) {
    yield put(actions.collectCreditError(error.message));
  }
}

function* handleAllowAnywhereRefueling(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);

    const response = yield call(api.allowAnywhereRefueling, {
      organizationId: currentOrganization.id,
      userId: payload.userId,
      data: payload.data,
    });

    if (!response) {
      throw new Error('request error');
    }

    if (response.data?.error) {
      throw new Error(response.data?.message);
    }

    const { users, balance, hasAnywhere } = yield select(selectors.getBalance);

    const updatedUsers = users.map(user => {
      if (user.id === payload.userId) {
        return {
          ...user,
          allowAnywhereRefueling: +payload.data.allowAnywhereRefueling,
        };
      }

      return user;
    });

    const data = {
      balance,
      users: updatedUsers,
      hasAnywhere,
    };

    yield put(actions.balanceSuccess(data));
    yield put(actions.balanceRequest(payload.params));
  } catch (error) {
    yield put(actions.balanceError(error.message));
  }
}

function* watchDistributionRequest(api) {
  yield takeEvery(types.DISTRIBUTION.REQUEST, handleDistributionRequest, api);
}

function* watchShareBalanceFromUser(api) {
  yield takeEvery(types.TOGGLE_SHARE, handleShareBalanceToggle, api);
}

function* watchUpdateBalanceRequest(api) {
  yield takeEvery(types.UPDATE.REQUEST, handleUpdateBalanceRequest, api);
}

function* watchCollectCreditRequest(api) {
  yield takeEvery(
    types.COLLECT_CREDIT.REQUEST,
    handleCollectCreditRequest,
    api
  );
}

function* watchAllowAnywhereRefueling(api) {
  yield takeEvery(
    types.TOGGLE_ANYWHERE_REFUELING,
    handleAllowAnywhereRefueling,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchDistributionRequest, api),
    fork(watchShareBalanceFromUser, api),
    fork(watchUpdateBalanceRequest, api),
    fork(watchCollectCreditRequest, api),
    fork(watchAllowAnywhereRefueling, api),
  ]);
}
