import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';

import { getCurrentOrganization } from 'redux/organizations/selectors';
import {
  listFuelManagementRequest as listFuelManagementRequestAction,
  listFuelManagementSuccess,
  listFuelManagementError,
  FUEL_MANAGEMENT_LIST_REQUEST,
  FUEL_MANAGEMENT_REFUEL_REQUEST,
  refuelFuelManagementSuccess,
  refuelFuelManagementError,
} from './actions';

function* listFuelManagement(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.list,
    APIParams({ ...payload.params, organizationId: currentOrganization.id })
  );

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listFuelManagementSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(listFuelManagementError(message));
  }
}

function* refuelFuelManagement(api, { payload }) {
  const response = yield call(api.refuel, payload.id, payload.payload);

  if (response.ok) {
    yield put(refuelFuelManagementSuccess());
    yield put(listFuelManagementRequestAction());
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(refuelFuelManagementError(message));
  }
}

export function* watchListFuelManagement(api) {
  yield takeEvery(FUEL_MANAGEMENT_LIST_REQUEST, listFuelManagement, api);
}

export function* watchRefuelFuelManagement(api) {
  yield takeEvery(FUEL_MANAGEMENT_REFUEL_REQUEST, refuelFuelManagement, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListFuelManagement, api),
    fork(watchRefuelFuelManagement, api),
  ]);
}
