import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import * as actions from './actions';
import { getCurrentOrganization } from '../organizations/selectors';

function* processRoute(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.processRoute,
    {
      organizationId: currentOrganization.id,
    },
    payload.data
  );

  if (response.ok) {
    const { wayPoints, ...route } = response.data;

    yield put(actions.onProcessRouteSuccess({ wayPoints, route }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(actions.onProcessRouteError(message));
  }
}

export function* watchProcessRoute(api) {
  yield takeEvery(actions.ROUTE_PROCESS_REQUEST, processRoute, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchProcessRoute, api)]);
}
