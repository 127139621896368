import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { serviceAPIParams } from 'helpers/Utils';
import { isNil } from 'lodash';
import { getCurrentOrganization } from '../../organizations/selectors';
import * as selectors from '../selectors';
import * as actions from './actions';

function* handleVehicleDistributionRequest(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);
    const response = yield call(
      api.balanceDistribution,
      serviceAPIParams({
        ...payload.params,
        organizationId: currentOrganization.id,
      })
    );

    if (!response) {
      throw new Error('request error');
    }

    const data = {
      balance: response.data.balance,
      vehicles: response.data.vehicles.map(vehicle => ({
        sharedBalance: vehicle.wallets.some(wallet => wallet.sharedBalance)
          ? 1
          : 0,
        balance: vehicle.wallets.reduce(
          (total, wallet) => total + wallet.balance,
          0
        ),
        id: vehicle.id,
        licensePlate: vehicle.licensePlate,
        make: vehicle.make,
        model: vehicle.model,
        wallets: vehicle.wallets,
        allowAnywhereRefueling: vehicle.wallets.every(
          wallet => wallet.allowAnywhereRefueling
        )
          ? 1
          : 0,
      })),
      hasAnywhere: response.data.hasAnywhere,
    };

    yield put(actions.expenseCenterVehicleBalanceSuccess(data));
  } catch (error) {
    yield put(actions.expenseCenterVehicleBalanceError(error.message));
  }
}

function* handleShareVehicleBalanceToggle(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);

    const response = yield call(api.unshareVehicleBalance, {
      organizationId: currentOrganization.id,
      id: payload.id,
      data: {
        sharedBalance: payload.status,
        ...(payload.wallet && { wallet: payload.wallet }),
        ...(payload.expenseCenter && {
          expenseCenter: payload.expenseCenter,
        }),
        ...(!isNil(payload.allowAnywhereRefueling) && {
          allowAnywhereRefueling: payload.allowAnywhereRefueling,
        }),
      },
    });

    if (!response) {
      throw new Error('request error');
    }

    const { vehicles, balance, hasAnywhere } = yield select(
      selectors.getExpenseCenterVehicleBalance
    );
    let newBalanceValue = balance;

    const updatedVehicles = vehicles.map(vehicle => {
      if (vehicle.id === payload.id) {
        newBalanceValue += vehicle.balance;

        const wallets = vehicle.wallets.map(wallet => {
          if (wallet._id === payload.wallet) {
            return {
              ...wallet,
              balance: 0,
              sharedBalance: payload.status,
            };
          }

          return {
            ...wallet,
            balance: 0,
            ...(wallet.sharedBalance &&
              payload.status && { sharedBalance: false }),
          };
        });

        if (!payload.wallet) wallets.push(response.data);

        return {
          ...vehicle,
          sharedBalance: +payload.status,
          balance: 0,
          wallets,
        };
      }

      return vehicle;
    });

    const data = {
      balance: newBalanceValue,
      vehicles: updatedVehicles,
      hasAnywhere,
    };

    yield put(actions.expenseCenterVehicleBalanceSuccess(data));
  } catch (error) {
    yield put(actions.expenseCenterVehicleBalanceError(error.message));
  }
}

function* handleUpdateVehicleBalanceRequest(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);
    const { vehicles, balance, hasAnywhere } = yield select(
      selectors.getExpenseCenterVehicleBalance
    );

    const totalToAdd = payload.data.reduce(
      (accumulator, currentValue) => accumulator.share + currentValue.share,
      { share: 0 }
    );

    if (totalToAdd > balance) {
      throw new Error('O saldo total ultrapassa o limite disponível');
    }

    const request = yield call(api.updateVehicleSharedBalance, {
      data: payload.data,
      organizationId: currentOrganization.id,
    });

    if (!request.ok) {
      throw new Error('update shared balance');
    }

    yield put(
      actions.expenseCenterVehicleBalanceSuccess({
        balance: balance - totalToAdd,
        vehicles,
        hasAnywhere,
      })
    );

    yield put(actions.expenseCenterUpdateVehicleBalanceSuccess());
  } catch (error) {
    yield put(actions.expenseCenterUpdateVehicleBalanceError(error.message));
  }
}

function* handleCollectVehicleCreditRequest(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);
    const { vehicles, balance, hasAnywhere } = yield select(
      selectors.getExpenseCenterVehicleBalance
    );

    let collectedBalance = 0;

    const dataWithUpdates = vehicles.map(d => {
      if (d.id !== payload.id) {
        return d;
      }

      const wallets = d.wallets.map(wallet => {
        if (payload.wallets.includes(wallet.id)) {
          collectedBalance += wallet.balance;
          return { ...wallet, balance: 0 };
        }
        return wallet;
      });

      return {
        ...d,
        wallets,
        balance: Math.abs(d.balance - collectedBalance),
      };
    });

    const request = yield call(api.reapVehicleBalance, {
      id: payload.id,
      organizationId: currentOrganization.id,
      data: payload.wallets,
    });

    if (!request.ok) {
      throw new Error('update shared balance');
    }

    console.log({
      balance: balance + collectedBalance,
      vehicles: dataWithUpdates,
      hasAnywhere,
    });

    yield put(
      actions.expenseCenterVehicleBalanceSuccess({
        balance: balance + collectedBalance,
        vehicles: dataWithUpdates,
        hasAnywhere,
      })
    );

    yield put(actions.expenseCenterCollectVehicleCreditSuccess());
  } catch (error) {
    yield put(actions.expenseCenterCollectVehicleCreditError(error.message));
  }
}

function* handleAllowAnywhereVehicleRefueling(api, { payload }) {
  try {
    const currentOrganization = yield select(getCurrentOrganization);

    const response = yield call(api.allowAnywhereRefueling, {
      organizationId: currentOrganization.id,
      vehicleId: payload.vehicleId,
      data: payload.data,
    });

    if (!response) {
      throw new Error('request error');
    }

    if (response.data?.error) {
      throw new Error(response.data?.message);
    }

    const { vehicles, balance, hasAnywhere } = yield select(
      selectors.getExpenseCenterVehicleBalance
    );

    const updatedVehicles = vehicles.map(vehicle => {
      if (vehicle.id === payload.vehicleId) {
        return {
          ...vehicle,
          allowAnywhereRefueling: +payload.data.allowAnywhereRefueling,
        };
      }

      return vehicle;
    });

    const data = {
      balance,
      vehicles: updatedVehicles,
      hasAnywhere,
    };

    yield put(actions.expenseCenterVehicleBalanceSuccess(data));
  } catch (error) {
    yield put(actions.expenseCenterVehicleBalanceError(error.message));
  }
}

function* watchVehicleDistributionRequest(api) {
  yield takeEvery(
    actions.EXPENSE_CENTER_VEHICLE.DISTRIBUTION.REQUEST,
    handleVehicleDistributionRequest,
    api
  );
}

function* watchShareVehicleBalanceFromVehicle(api) {
  yield takeEvery(
    actions.EXPENSE_CENTER_VEHICLE.TOGGLE_SHARE,
    handleShareVehicleBalanceToggle,
    api
  );
}

function* watchUpdateVehicleBalanceRequest(api) {
  yield takeEvery(
    actions.EXPENSE_CENTER_VEHICLE.UPDATE.REQUEST,
    handleUpdateVehicleBalanceRequest,
    api
  );
}

function* watchCollectVehicleCreditRequest(api) {
  yield takeEvery(
    actions.EXPENSE_CENTER_VEHICLE.COLLECT_CREDIT.REQUEST,
    handleCollectVehicleCreditRequest,
    api
  );
}

function* watchAllowAnywhereRefueling(api) {
  yield takeEvery(
    actions.EXPENSE_CENTER_VEHICLE.TOGGLE_ANYWHERE_REFUELING,
    handleAllowAnywhereVehicleRefueling,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchVehicleDistributionRequest, api),
    fork(watchShareVehicleBalanceFromVehicle, api),
    fork(watchUpdateVehicleBalanceRequest, api),
    fork(watchCollectVehicleCreditRequest, api),
    fork(watchAllowAnywhereRefueling, api),
  ]);
}
