import produce from 'immer';

import {
  ORGANIZATION_INVOICES_LIST_REQUEST,
  ORGANIZATION_INVOICES_LIST_SUCCESS,
  ORGANIZATION_INVOICES_LIST_ERROR,
  ORGANIZATION_INVOICES_TRANSACTIONS_REQUEST,
  ORGANIZATION_INVOICES_TRANSACTIONS_SUCCESS,
  ORGANIZATION_INVOICES_TRANSACTIONS_ERROR,
} from './actions';

const INITIAL_STATE = {
  list: [],
  transactions: {
    list: [],
    currentPage: '',
    totalPages: '',
    totalItems: '',
    skipItems: 0,
    error: '',
    loading: false,
    success: false,
  },
  makePayment: {
    loading: false,
    error: '',
    success: false,
  },
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
};

export default (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ORGANIZATION_INVOICES_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case ORGANIZATION_INVOICES_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }
      case ORGANIZATION_INVOICES_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }
      case ORGANIZATION_INVOICES_TRANSACTIONS_REQUEST: {
        draft.transactions.loading = true;
        draft.transactions.error = '';
        break;
      }
      case ORGANIZATION_INVOICES_TRANSACTIONS_SUCCESS: {
        draft.transactions.loading = false;
        draft.transactions.list = action.payload.list;
        draft.transactions.currentPage = action.payload.pages.currentPage;
        draft.transactions.totalPages = action.payload.pages.totalPages;
        draft.transactions.totalItems = action.payload.items.totalItems;
        draft.transactions.skipItems = action.payload.items.skipItems;
        draft.transactions.error = '';
        break;
      }
      case ORGANIZATION_INVOICES_TRANSACTIONS_ERROR: {
        draft.transactions.loading = false;
        draft.transactions.list = [];
        draft.transactions.error = action.payload.message;
        break;
      }
      default:
        return state;
    }
  });
};
