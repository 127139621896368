import { all } from 'redux-saga/effects';
import api from '../services';
import authSagas from './auth/saga';
import userSagas from './user/saga';
import vehicleSagas from './vehicle/saga';
import containerSagas from './container/saga';
import driverSagas from './driver/saga';
import recipientSagas from './recipient/saga';
import transactionSagas from './transaction/saga';
import billsSagas from './bills/saga';
import stationsSagas from './stations/saga';
import organizationsSagas from './organizations/saga';
import administratorsSagas from './administrators/saga';
import reportsSagas from './reports/saga';
import depositsSagas from './deposits/saga';
import mapsSagas from './maps/saga';
import balanceSagas from './balance/saga';
import hydrateSagas from './hydrate/saga';
import orgSettingsSagas from './orgSettings/saga';
import balanceSharing from './balanceSharing/saga';
import permissionsSagas from './permissions/saga';
import userPermissionsSagas from './userPermissions/saga';
import vehicleBalanceSagas from './balance/vehicleBalance/saga';
import fuelManagementSagas from './fuelManagement/saga';
import purchaseSagas from './purchase/saga';
import expenseCenterSagas from './expenseCenter/saga';
import expenceCenterUserBalanceSagas from './expenseCenterBalance/user/saga';
import expenceCenterVehicleBalanceSagas from './expenseCenterBalance/vehicle/saga';
import subscriptionSagas from './subscription/saga';
import fuelManagementWalletsSagas from './fuelManagement/wallets/saga';
import fuelManagementUsersSagas from './fuelManagement/users/saga';
import routeScriptSagas from './routeScript/saga';
import organizationInvoiceSagas from './organizationInvoices/saga'; 
import aggregateTransactionsSagas from './aggregateTransactions/saga' 
import aggregateBalanceSharingSagas from './aggregateBalanceSharing/saga';

export default function* rootSaga() {
  yield all([
    authSagas(api.auth),
    userSagas(api.user),
    vehicleSagas(api.vehicle),
    containerSagas(api.container),
    driverSagas(api.driver),
    recipientSagas(api.recipient),
    transactionSagas({
      transactions: api.transactions,
      admin: api.admin,
    }),
    billsSagas(api.bills),
    stationsSagas(api.stations),
    organizationsSagas(api.organizations),
    administratorsSagas(api.admin),
    reportsSagas(api.report),
    depositsSagas(api.deposits),
    mapsSagas(),
    balanceSagas({
      ...api.balance,
      getAllUsers: api.admin.getAllUsers,
      getUsersById: api.admin.getUsersById,
      ...api.anywhere,
    }),
    hydrateSagas(api.hydrate),
    orgSettingsSagas(api.settings),
    balanceSharing(api.report),
    permissionsSagas(api.permissions),
    userPermissionsSagas(api.userPermissions),
    vehicleBalanceSagas({ ...api.balance, ...api.anywhere }),
    fuelManagementSagas(api.fuelManagement),
    purchaseSagas(api.purchase),
    expenseCenterSagas(api.expenseCenter),
    expenceCenterUserBalanceSagas({
      ...api.expenseCenterBalance,
      ...api.anywhere,
    }),
    expenceCenterVehicleBalanceSagas({
      ...api.expenseCenterBalance,
      ...api.anywhere,
    }),
    subscriptionSagas({
      subscription: api.subscription,
    }),
    fuelManagementWalletsSagas(api.fuelManagement),
    fuelManagementUsersSagas(api.fuelManagement),
    routeScriptSagas(api.routeScript),
    organizationInvoiceSagas(api.organizationInvoices),
    aggregateTransactionsSagas(api.aggregateTransactions),
    aggregateBalanceSharingSagas(api.aggregateBalanceSharing),
  ]);
}
