export const ORGANIZATION_INVOICES_LIST_REQUEST =
  'ORGANIZATION_INVOICES_LIST_REQUEST';
export const ORGANIZATION_INVOICES_LIST_SUCCESS =
  'ORGANIZATION_INVOICES_LIST_SUCCESS';
export const ORGANIZATION_INVOICES_LIST_ERROR =
  'ORGANIZATION_INVOICES_LIST_ERROR';

export const ORGANIZATION_INVOICES_TRANSACTIONS_REQUEST =
  'ORGANIZATION_INVOICES_TRANSACTIONS_REQUEST';
export const ORGANIZATION_INVOICES_TRANSACTIONS_SUCCESS =
  'ORGANIZATION_INVOICES_TRANSACTIONS_SUCCESS';
export const ORGANIZATION_INVOICES_TRANSACTIONS_ERROR =
  'ORGANIZATION_INVOICES_TRANSACTIONS_ERROR';



export const listOrganizationInvoicesRequest = params => ({
  type: ORGANIZATION_INVOICES_LIST_REQUEST,
  payload: { params },
});

export const listOrganizationInvoicesSuccess = payload => ({
  type: ORGANIZATION_INVOICES_LIST_SUCCESS,
  payload,
});

export const listOrganizationInvoicesError = message => ({
  type: ORGANIZATION_INVOICES_LIST_ERROR,
  payload: { message },
});

export const organizationInvoicesTransactions = params => ({
  type: ORGANIZATION_INVOICES_TRANSACTIONS_REQUEST,
  payload: { params },
});

export const organizationInvoicesTransactionsSuccess = payload => ({
  type: ORGANIZATION_INVOICES_TRANSACTIONS_SUCCESS,
  payload,
});

export const organizationInvoicesTransactionsError = message => ({
  type: ORGANIZATION_INVOICES_TRANSACTIONS_ERROR,
  payload: { message },
});