import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/organization/invoice${objectToURLParams(parameters)}`);

export const getInvoiceTransactions = invoiceId =>
  api.get(`/admin/invoice/${invoiceId}/transaction`);

export const getTransactions = parameters =>
  api.get(`/admin/transaction${objectToURLParams(parameters)}`);
